<template>
  <LayoutTelas id="tela_empresas" :titulo="this.$route.meta.titulo">
    <div class='botoes'>
      <b-button
        type="button"
        variant="success"
        size="sm" 
        class="btnSalvar"
        @click="salvarAlteracoes()"
        :disabled="dados.disabledCampos"><v-icon scale="0.8" name="save"/> Salvar</b-button>
    </div>

    <b-tabs small content-class="mt-3">
      <b-tab title="geral" active>
        <template #title>Geral</template>
        <div>
          <InputBasico
            ref="refToken"
            label="Token"
            name="token"
            v-model="dados.empresaTemp.token"
            :disabled="true"
            size="sm"
            type="text"/>
          <InputBasico
            ref="refDataCadastro"
            label="Data Cadastro"
            name="dataCadastro"
            v-model="dados.empresaTemp.dataCadastro"
            size="sm"
            :disabled="true"
            type="text"/>  
          <InputBasico
            ref="refEmailNotificacao"
            label="E-mail Notificação"
            name="emailNotificacao"
            v-model="dados.empresaTemp.email_notificacao"
            size="sm"
            :disabled="false"
            type="text"/>  
          <InputBasico
            ref="refStatus"
            label="Status"
            size="sm"
            name="status"
            v-model="dados.empresaTemp.status ? 'Ativo':'Inativo'"
            :disabled="true"
            type="text"/>  
        </div>
      </b-tab>
      <b-tab title="customizacao">
        <template #title>Customização</template>
        <div>
          <b-form-select
            ref="refCustomizacoes"
            label="Customizações"
            name="customizacoes"
            size="sm"
            :options="getCustomizacoes()"
            v-model="dados.customSel"
            :disabled="dados.disabledCampos"
            v-validate="{required:true}"
            :errorsBag="errors"
            @change="setCustomValue()"/>
          <b-form-textarea
            id="textarea-rows"
            name="customizacao"
            size="sm"
            v-model="dados.customSelValue"
            rows="8"></b-form-textarea>
          <b-button
            variant="primary"
            size="sm"
            class="btnAplicar"
            :disabled="dados.disabledCampos"
            @click="aplicarCustomizacao()">Aplicar</b-button>
        </div>
      </b-tab>
      <b-tab title="Sapiens">
        <template #title>ERP</template>
        <div>
          <label for="erp.tipo">Tipo</label>
          <b-form-select
            id="erp.tipo"
            :options="dados.opcoesERP"
            size="sm"
            v-model="dados.empresaTemp.erp.tipo"
            :disabled="dados.disabledCampos"
            v-validate="{required:true}"/>

          <br><br>

          <b-tabs small content-class="mt-3">
            <b-tab title="Conexão Extrator" active>
              <template #title>Conexão Extrator</template>
              <div>
                <InputBasico
                  label="Endereço"
                  name="endereco"
                  v-model="dados.empresaTemp.erp.conexao.url"
                  :disabled="dados.disabledCampos"
                  size="sm"
                  type="text"/>
                <InputBasico
                  label="Usuário"
                  name="usuario"
                  v-model="dados.empresaTemp.erp.conexao.usuario"
                  size="sm"
                  :disabled="dados.disabledCampos"
                  type="text"/>  
                <InputBasico
                  label="Senha"
                  name="senha"
                  v-model="dados.empresaTemp.erp.conexao.senha"
                  size="sm"
                  :disabled="dados.disabledCampos"
                  type="password"/>  
              </div>
            </b-tab>
            <b-tab title="URLs">
              <template #title>End-points Extrator</template>
              <div>
                <InputBasico
                  label="Lista Inventário"
                  name="erp.url.inventario"
                  v-model="dados.empresaTemp.erp.URLS['LISTA_INVENTARIO']"
                  size="sm"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Lista Inventário Detalhada"
                  name="erp.url.inventarioDetalhada"
                  v-model="dados.empresaTemp.erp.URLS['LISTA_INVENTARIO_DETALHE']"
                  size="sm"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Lista Lançamento Contábil"
                  name="erp.url.lancamento"
                  size="sm"
                  v-model="dados.empresaTemp.erp.URLS['LISTA_CONTABIL']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Lista Lançamento Contábil Detalhada"
                  name="erp.url.lancDetalhada"
                  size="sm"
                  v-model="dados.empresaTemp.erp.URLS['LISTA_CONTABIL_DETALHE']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Lista Notas"
                  name="erp.url.notas"
                  size="sm"
                  v-model="dados.empresaTemp.erp.URLS['LISTA_NOTA']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Lista Notas Detalhada"
                  name="erp.url.notasDetalhada"
                  size="sm"
                  v-model="dados.empresaTemp.erp.URLS['LISTA_NOTA_DETALHE']"
                  :disabled="dados.disabledCampos"
                  type="text"/>                  
                <InputBasico
                  label="Recebe Status das Notas Emitidas"
                  name="erp.url.statusNotas"
                  size="sm"
                  v-model="dados.empresaTemp.erp.URLS['NOTA_SET_STATUS']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
              </div>
            </b-tab>
          </b-tabs>            
        </div>
      </b-tab>
      <b-tab title="edocs">
        <template #title>e-Docs</template>
        <div>
          <InputBasico
            label="Endereço WSDL"
            name="edocs.endereco_wsdl"
            size="sm"
            v-model="dados.empresaTemp.eDocs['url']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="Endereço Base"
            name="edocs.endereco_base"
            size="sm"
            v-model="dados.empresaTemp.eDocs['urlBase']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="Usuário"
            name="edocs.usuario"
            size="sm"
            v-model="dados.empresaTemp.eDocs['usuario']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="Senha"
            name="edocs.senha"
            v-model="dados.empresaTemp.eDocs['senha']"
            size="sm"
            :disabled="dados.disabledCampos"
            type="password"/>           
        </div>
      </b-tab> 
      <b-tab title="empresa">
        <template #title>Dados Empresa</template>
        <div>
          <InputBasico
            label="ID"
            name="empresa.id"
            size="sm"
            v-model="dados.empresaTemp.empresa['id']"
            :disabled="true"
            type="text"/>
          <InputBasico
            label="ID Senior"
            name="empresa.idSenior"
            size="sm"
            v-model="dados.empresaTemp.empresa['idSenior']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="ID ERP Origem"
            name="empresa.idSAP"
            size="sm"
            v-model="dados.empresaTemp.empresa['idSAP']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="ID ERP Sapiens"
            name="empresa.idSapiens"
            size="sm"
            v-model="dados.empresaTemp.empresa['idSapiens']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="Nome"
            name="empresa.nome"
            size="sm"
            v-model="dados.empresaTemp.empresa['nome']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="CNPJ"
            name="empresa.cnpj"
            size="sm"
            v-model="dados.empresaTemp.empresa['cnpj']"
            :disabled="dados.disabledCampos"
            type="text"/>
          <InputBasico
            label="Regime Tributário"
            name="empresa.regime_tributario"
            size="sm"
            v-model="dados.empresaTemp.empresa['regime_tributario']"
            :disabled="dados.disabledCampos"
            type="text"/>

          <b-tabs small content-class="mt-3">
            <b-tab title="edocs">
              <template #title>Filiais</template>
              <div>
                <b-table
                  striped
                  hover
                  bordered
                  small
                  head-variant="dark"
                  :items="dados.empresaTemp.filiais"
                  :fields="dados.fieldsFilial"
                  @row-clicked="selecionouLinha">

                  <template #head(actions)="row">
                    <center>Ações</center>
                  </template>

                  <template #cell(actions)="row">
                    <center>
                      <b-button class="btn btn-danger btnAction" size="sm" @click="excluirLinha(`${row.item.codFilial}`)"><v-icon scale="0.8" name="trash"/></b-button>
                    </center>
                  </template>
                </b-table>
                <div class='botoes'>
                  <b-button
                    type="button"
                    variant="success"
                    size="sm" 
                    class="btnAdicionar"
                    @click="abrirJanelaModal({codFilial:null})"
                    :disabled="dados.disabledCampos"><v-icon scale="0.8" name="plus"/> Adicionar</b-button>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-tab>     
      <b-tab title="sapiens">
        <template #title>ERP Sapiens</template>
        <div>
          <b-tabs small content-class="mt-3">
            <b-tab title="autenticacao">
              <template #title>Autenticação</template>
              <div>
                <InputBasico
                  label="Endereço"
                  name="autenticacao.url"
                  size="sm"
                  v-model="dados.empresaTemp.autenticacao.url"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Usuário"
                  name="autenticacao.parametros.user"
                  size="sm"
                  v-model="dados.empresaTemp.autenticacao.parametros['user']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Senha"
                  name="autenticacao.parametros.password"
                  size="sm"
                  v-model="dados.empresaTemp.autenticacao.parametros['password']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Encryption"
                  name="autenticacao.parametros.encryption"
                  size="sm"
                  v-model="dados.empresaTemp.autenticacao.parametros['encryption']"
                  :disabled="dados.disabledCampos"
                  type="text"/>
                <InputBasico
                  label="Parameters"
                  name="autenticacao.parametros.parameters"
                  size="sm"
                  v-model="dados.empresaTemp.autenticacao.parametros['parameteres']"
                  :disabled="dados.disabledCampos"
                  type="text"/>                          
              </div>
            </b-tab>            
          </b-tabs>            
        </div>
      </b-tab>     
    </b-tabs>

    <b-modal
      id="modal-adicionar"
      ref="modal-adicionar"
      :title="dados.filialSel.codFilial ? 'Alterar':'Adicionar'"
      @hidden="hiddeModal"
      @show="showModal"
      @ok="handleOk">

      <b-form ref="refDeParamensagem" @submit.stop.prevent="handleSubmit">
        <InputBasico
          label="Cód. Filial ERP"
          name="filialSel.codFilial"
          v-model="dados.filialSel.codFilial"
          type="text"
          v-validate="{ required:true}"
          :disabled="dados.disabledCampos"/>
        <InputBasico
          label="CNPJ"
          name="filialSel.cnpj"
          v-model="dados.filialSel.cnpj"
          type="text"
          :disabled="dados.disabledCampos"/>
        <InputBasico
          label="Cód. Filial Sapiens"
          name="filialSel.codFil"
          v-model="dados.filialSel.codFil"
          type="text"
          :disabled="dados.disabledCampos"/>
        <InputBasico
          label="Depósito"
          name="filialSel.codDep"
          v-model="dados.filialSel.codDep"
          type="text"
          :disabled="dados.disabledCampos"/>
        <InputSelect
          label="UF"
          name="filialSel.codFil"
          v-model="dados.filialSel.codUfSefaz"
          :options="dados.opcoesUfSefaz"
          type="text"
          :disabled="dados.disabledCampos"/>
        <label for="filialSel.status">Status</label><br>  
        <toggle-button 
          id="filialSel.status" 
         :labels="{checked: 'On', unchecked: 'Off'}"
          v-model="dados.filialSel.status"/>
      </b-form>

      <b-alert v-model="dados.modalError" variant="danger" dismissible>
        {{dados.erroModal}}
      </b-alert>

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()" :disabled="dados.disabledCampos">Cancelar</b-button>
        <b-button size="sm" variant="success" @click="handleOk" :disabled="dados.disabledCampos">Salvar</b-button>
      </template>
    </b-modal>
  </LayoutTelas>
</template>

<script>
import Processando from '@/components/comuns/Processando'
import InputBasico from "@/components/inputs/InputBasico";
import InputSelect from "@/components/inputs/InputSelect";
import { getUserObj } from "@/common/localStorage/localStorage";
import {atualizarEmpresa} from '@/common/services/geralServices';
import { ToggleButton } from 'vue-js-toggle-button';

export default {
  created() {
    this.dados.empresaTemp = getUserObj();
  },
  data() {
    return {
      dados: {
        empresaTemp: null,
        disabledCampos: false,
        customSel: null,
        customSelValue: null,
        filialSel: {
          codFilial : null,
          cnpj : null,
          codFil : null,
          codDep : null,
          status : true,
          codUfSefaz : null
        },
        opcoesERP: [
          {text:'Selecione...',value:null},
          {text:'Infor-LN',value:'INFORLN'},
          {text:'Infor-LX',value:'INFORLX'},
          {text:'Oracle-EBS',value:'ORACLEEBS'},
          {text:'SAP',value:'SAP'},
          {text:'Outros',value:'OUTROS'}
        ],
        fieldsFilial: [
          { key: "codFilial", label: "Cód Filial ERP", sortable: true },
          { key: "cnpj", label: "CNPJ", sortable: true },
          { key: "codFil", label: "Cód. Filial Sapiens", sortable: true },
          { key: "codDep", label: "Cód. Depósito Sapiens", sortable: true },
          { key: "status", label: "Status", sortable: true },
          { key: "codUfSefaz", label: "Cód UF Sefaz", sortable: true },
          { key: "actions", label: "Ações", sortable: false }
        ],
        opcoesUfSefaz: [
          {value:'11',text:'RO'},
          {value:'12',text:'AC'},
          {value:'13',text:'AM'},
          {value:'14',text:'RR'},
          {value:'15',text:'PA'},
          {value:'16',text:'AP'},
          {value:'17',text:'TO'},
          {value:'21',text:'MA'},
          {value:'22',text:'PI'},
          {value:'23',text:'CE'},
          {value:'24',text:'RN'},
          {value:'25',text:'PB'},
          {value:'26',text:'PE'},
          {value:'27',text:'AL'},
          {value:'28',text:'SE'},
          {value:'29',text:'BA'},
          {value:'31',text:'MG'},
          {value:'32',text:'ES'},
          {value:'33',text:'RJ'},
          {value:'35',text:'SP'},
          {value:'41',text:'PR'},
          {value:'42',text:'SC'},
          {value:'43',text:'RS'},
          {value:'50',text:'MS'},
          {value:'51',text:'MT'},
          {value:'52',text:'GO'},
          {value:'53',text:'DF'}
        ]
      }
    }
  },
  components: {
    Processando,
    InputBasico,
    InputSelect,
    ToggleButton
  },
  methods: {
    getCustomizacoes() {
      let retorno = [{text:"Selecione...", value : null}];
      if (this.dados.empresaTemp.customizacao) {
        for (const key in this.dados.empresaTemp.customizacao) {
          retorno.push({text:key, value:key})
        }
      }

      return retorno;
    },
    aplicarCustomizacao() {
      if (this.dados.customSel != "Selecione...") {
        this.dados.empresaTemp.customizacao[this.dados.customSel] = this.dados.customSelValue;
      }
    },
    setCustomValue(e,valor) {
      this.dados.customSelValue = this.dados.empresaTemp.customizacao[this.dados.customSel];
    },
    async salvarAlteracoes() {
      let ret = await atualizarEmpresa(this.dados.empresaTemp);
    },
    abrirJanelaModal(value) {
      if (!value.codFilial) {
        // this.dados.filialSel = {
        //   codFilial : null,
        //   cnpj : null,
        //   codFil : null,
        //   codDep : null,
        //   status : true,
        //   codUfSefaz : null
        // }
        this.dados.filialSel = {};
      }
      this.$bvModal.show('modal-adicionar');
    },
    selecionouLinha(item,index,event) {
      this.dados.filialSel = item;

      this.abrirJanelaModal(this.dados.filialSel);
    },
    checkFormValidity() {
      let valid = true;

      // if (this.dados.itemSel.codSenior === '' || this.dados.itemSel.codSenior === undefined) {
      //     this.dados.erroModal = 'É obrigatório preencher o campo "Cód. Senior"';
      //     this.dados.modalError = true;
      //     return false;
      // }
      
      // if (this.dados.itemSel.descricao === '' || this.dados.itemSel.descricao === undefined) {
      //     this.dados.erroModal = 'É obrigatório preencher o campo "Descrição"';
      //     this.dados.modalError = true;
      //     return false;
      // }

      // if (this.dados.itemSel.codGoverno === '' || this.dados.itemSel.codGoverno === undefined) {
      //     this.dados.erroModal = 'É obrigatório preencher o campo "Cód. NCM"';
      //     this.dados.modalError = true;
      //     return false;
      // }
      return valid;
    },
    hiddeModal() {
      this.dados.modalError = false;
    },
    showModal() {
      // this.$nextTick(function () {
      //     this.$refs.refDeParamensagem_c1.focus()
      // })
    },
    handleOk(bvModalEvt) {
      // Parando processamento
      bvModalEvt.preventDefault()
      // executando o submit
      this.handleSubmit()
    },
    async handleSubmit() {
      this.dados.erroModal = '';
      this.dados.modalError = false;

      if (!this.checkFormValidity()) {
        return
      }

      //Envia dados ao servidor  
      this.salvarAlteracoesFilial();
      //Fecha a janela
      this.$nextTick(() => {
        this.$bvModal.hide('modal-adicionar');
      })
    },
    salvarAlteracoesFilial() {
      let filial =  this.dados.empresaTemp.filiais.findIndex(f=> f.codFilial == this.dados.filialSel.codFilial);
      
      if (filial == -1) {
        this.dados.empresaTemp.filiais.push(this.dados.filialSel);
      } else {
        this.dados.empresaTemp.filiais[filial] = {
          codFilial: this.dados.filialSel.codFilial,
          cnpj : this.dados.filialSel.cnpj,
          codFil : this.dados.filialSel.codFil,
          codDep : this.dados.filialSel.codDep,
          status : this.dados.filialSel.status,
          codUfSefaz : this.dados.filialSel.codUfSefaz
        }
      }
    },
    excluirLinha(valor) {
      this.dados.empresaTemp.filiais = this.dados.empresaTemp.filiais.filter(f=>f.codFilial != valor);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/common/scss/breakpoints.scss";

  #tela_empresas {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    @media (min-width: $medium-size) {
      min-width: $small-size;
    }
  }

  .botoes {
    display: grid;
    width: 100%;
    align-items: center; /* left and right */
    justify-content: end; /* up and down */
  }

  .paginacao {
    display: flex;
    justify-content: flex-end;

    &__seletor {
      margin: 0;
    }
  }
</style>